import TicketDetailsAdmin from '../views/admin/ticket/TicketDetailsAdmin.vue'
import TicketsAdmin from '../views/admin/ticket/TicketsAdmin.vue'
import Dashboard from '../views/admin/Dashboard.vue'
import ChangePasswordAdmin from '../views/ChangePassword.vue'
import UserDetailsProfileAdmin from '../views/UserDetails.vue'
import ProfileAdmin from '../views/Profile.vue'
import AdminLayout from '../layouts/Admin.vue'
import auth from '../helper/auth'

import FAQAdmin from '../views/admin/faq/FAQAdmin.vue'
import KnowledgeBaseAdmin from '../views/admin/knowledgeBase/KnowledgeBaseAdmin.vue'
// department
import DepartmentsAdmin from '../views/admin/department/DepartmentsAdmin.vue'
import CreateDepartmentAdmin from '../views/admin/department/CreateDepartmentAdmin.vue'
import EditDepartmentAdmin from '../views/admin/department/EditDepartmentAdmin.vue'
import AssignDepartmentAdmin from '../views/admin/department/AssignDepartmentAdmin.vue'
// category
import CategoriesAdmin from '../views/admin/category/CategoriesAdmin.vue'
import CreateCategoryAdmin from '../views/admin/category/CreateCategoryAdmin.vue'
import EditCategoryAdmin from '../views/admin/category/EditCategoryAdmin.vue'
// subCategory
import SubCategoriesAdmin from '../views/admin/subCategory/SubCategoriesAdmin.vue'
import CreateSubCategoryAdmin from '../views/admin/subCategory/CreateSubCategoryAdmin.vue'
import EditSubCategoryAdmin from '../views/admin/subCategory/EditSubCategoryAdmin.vue'
//customRole
/* import CustomRolesAdmin from "../views/admin/customRole/CustomRolesAdmin.vue";
import CreateCustomRoleAdmin from "../views/admin/customRole/CreateCustomRoleAdmin.vue";
import CustomRoleDetailsAdmin from "../views/admin/customRole/CustomRoleDetailsAdmin.vue";
import EditCustomRoleAdmin from "../views/admin/customRole/EditCustomRoleAdmin.vue"; */
// user admin
import UsersAdmin from '../views/admin/user/UsersAdmin.vue'
import CreateUserAdmin from '../views/admin/user/CreateUserAdmin.vue'
import EditUserAdmin from '../views/admin/user/EditUserAdmin.vue'
import UserDetailsAdmin from '../views/admin/user/UserDetailsAdmin.vue'
// assign agent
import AssignAgentAdmin from '../views/admin/assign/AssignAgentAdmin.vue'
import AssignSupervisorAdmin from '../views/admin/assign/AssignSupervisorAdmin.vue'
//other
import MyTicketsAdmin from '../views/admin/ticket/MyTicketsAdmin.vue'
// reports
import SLACompliance from '../views/admin/report/SLACompliance.vue'
import SLABreached from '../views/admin/report/SLABreached.vue'
import Top10Cases from '../views/admin/report/Top10Cases.vue'

const adminRoutes = [
  {
    path: '/admin',
    component: AdminLayout,
    beforeEnter: auth.ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
      },

      {
        path: 'sla-breached',
        name: 'SLABreached',
        component: () => import('../views/reports/SLABreached.vue')
      },

      {
        path: 'sla-compliance',
        name: 'SLACompliance',
        component: () => import('../views/reports/SLACompliance.vue')
      },

      {
        path: 'changes-history',
        name: 'ChangesHistory',
        component: () => import('../views/reports/ChangesHistory.vue')
      },

      {
        path: 'top-10-cases',
        name: 'Top10Cases',
        component: () => import('../views/reports/Top10Cases.vue')
      },

      {
        path: 'tickets-log',
        name: 'TicketsLog',
        component: () => import('../views/reports/TicketsLog.vue')
      },

      {
        path: 'users-log',
        name: 'UsersLog',
        component: () => import('../views/reports/UsersLog.vue')
      },

      {
        path: 'cbn-xml',
        name: 'CBNXml',
        component: () => import('../views/reports/CBNXml.vue')
      },

      {
        path: 'tickets/:status',
        name: 'TicketAdmin',

        component: TicketsAdmin
      },

      {
        path: 'ticket/:ref',
        name: 'TicketDetailsAdmin',

        component: TicketDetailsAdmin
      },

      {
        path: 'faqs',
        name: 'FAQAdmin',
        // component: () => import('../views/admin/faq/FAQAdmin.vue')
        component: FAQAdmin
      },
      {
        path: 'knowledge-base',
        name: 'KnowledgeBaseAdmin',
        // component: () => import('../views/admin/faq/FAQAdmin.vue')
        component: KnowledgeBaseAdmin
      },

      /* Departments */
      {
        path: 'departments',
        name: 'DepartmentsAdmin',
        component: DepartmentsAdmin
      },
      {
        path: 'department/create',
        name: 'CreateDepartmentAdmin',
        component: CreateDepartmentAdmin
      },
      {
        path: 'department/edit/:uuid',
        name: 'EditDepartmentAdmin',
        component: EditDepartmentAdmin
      },
      {
        path: 'department/assign/:uuid',
        name: 'AssignDepartmentAdmin',
        component: AssignDepartmentAdmin
      },

      /*  Categories Admin */
      {
        path: 'categories',
        name: 'CategoriesAdmin',
        //component: () => import('../views/admin/category/CategoriesAdmin.vue')
        component: CategoriesAdmin
      },
      {
        path: 'category/create',
        name: 'CreateCategoryAdmin',
        //component: () => import('../views/admin/category/CreateCategoryAdmin.vue')
        component: CreateCategoryAdmin
      },
      {
        path: 'category/edit/:uuid',
        name: 'EditCategoryAdmin',
        //component: () => import('../views/admin/category/EditCategoryAdmin.vue')
        component: EditCategoryAdmin
      },

      /* SubCategory */
      {
        path: 'sub-categories',
        name: 'SubCategoriesAdmin',
        //component: () => import('../views/admin/subCategory/SubCategoriesAdmin.vue')
        component: SubCategoriesAdmin
      },
      {
        path: 'sub-category/create',
        name: 'CreateSubCategoryAdmin',
        //component: () => import('../views/admin/subCategory/CreateSubCategoryAdmin.vue')
        component: CreateSubCategoryAdmin
      },
      {
        path: 'sub-category/edit/:uuid',
        name: 'EditSubCategoryAdmin',
        //component: () => import('../views/admin/subCategory/EditSubCategoryAdmin.vue')
        component: EditSubCategoryAdmin
      },

      /* Custom Role */
      /* {
            path: 'custom-roles',
            name: 'CustomRolesAdmin',
            //component: () => import('../views/admin/customRole/CustomRolesAdmin.vue')
            component: CustomRolesAdmin
        }, {
            path: 'custom-role/create',
            name: 'CreateCustomRoleAdmin',
            //component: () => import('../views/admin/customRole/CreateCustomRoleAdmin.vue')
            component: CreateCustomRoleAdmin
        }, {
            path: 'custom-role/:uuid',
            name: 'CustomRoleDetailsAdmin',
            //component: () => import('../views/admin/customRole/CustomRoleDetailsAdmin.vue')
            component: CustomRoleDetailsAdmin
        }, {
            path: 'custom-role/edit/:uuid',
            name: 'EditCustomRoleAdmin',
            //component: () => import('../views/admin/customRole/EditCustomRoleAdmin.vue')
            component: EditCustomRoleAdmin
        }, */

      /* User Admin */
      {
        path: 'users/:role',
        name: 'UsersAdmin',
        //component: () => import('../views/admin/user/UsersAdmin.vue')
        component: UsersAdmin
      },
      {
        path: 'user/create',
        name: 'CreateUserAdmin',
        //component: () => import('../views/admin/user/CreateUserAdmin.vue')
        component: CreateUserAdmin
      },
      {
        path: 'user/edit/:uuid',
        name: 'EditUserAdmin',
        //component: () => import('../views/admin/user/EditUserAdmin.vue')
        component: EditUserAdmin
      },
      {
        path: 'user/:uuid',
        name: 'UserDetailsProfileAdmin',
        //component: () => import('../views/admin/user/UserDetailsAdmin.vue')
        component: UserDetailsProfileAdmin
      },

      /* AssignAgent */
      {
        path: 'assign-agent/:uuid',
        name: 'AssignAgentAdmin',
        //component: () => import('../views/admin/assign/AssignAgentAdmin.vue')
        component: AssignAgentAdmin
      },
      /* AssignAgent */
      {
        path: 'assign-supervisor/:uuid',
        name: 'AssignSupervisorAdmin',
        //component: () => import('../views/admin/assign/AssignSupervisorAdmin.vue')
        component: AssignSupervisorAdmin
      },

      {
        path: 'my-tickets',
        name: 'MyTicketsAdmin',
        //component: () => import('../views/admin/ticket/MyTicketsAdmin.vue')
        component: MyTicketsAdmin
      },

      {
        path: 'profile',
        name: 'ProfileAdmin',
        component: ProfileAdmin
      },

      {
        path: 'user/:uuid',
        name: 'UserDetailsAdmin',
        component: UserDetailsAdmin
      },
      {
        path: 'change-password',
        name: 'ChangePasswordAdmin',
        component: ChangePasswordAdmin
      }
    ]
  }
]

export default adminRoutes
