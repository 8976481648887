<template>
  <div class="">
    <div class="sidebar" :class="compress ? 'compress' : ''">
      <div class="logo-details">
        <!-- <i class="bx bxl-c-plus-plus"></i> -->
        <div class="logo_icon">
          <img src="../assets/HOPE-PS-Bank-Logo.png" alt="" style="width: 50px; height:auto;" />
        </div>
        <div class="logo_name">Support</div>
      </div>
      <ul class="nav-links">
        <!-- Dashboard -->
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon-house-door></b-icon-house-door>
            <span class="link_name">Dashboard</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Dashboard</a></li>
          </ul>
        </li>

        <!-- Reports -->
        <li @click="showSubMenu('Reports')" :class="menu == 'Reports' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-bar-chart></b-icon-bar-chart>
              <span class="link_name">Reports</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Reports</a></li>
            <li><router-link :to="{ name: 'SLACompliance' }">SLA Compliance</router-link></li>
            <li><router-link :to="{ name: 'SLABreached' }">SLA Breached</router-link></li>
            <li><router-link :to="{ name: 'ChangesHistory' }">Changes History</router-link></li>
            <li><router-link :to="{ name: 'Top10Cases' }">Top 10 Cases</router-link></li>
            <li><router-link :to="{ name: 'CBNXml' }">Regulatory</router-link></li>
          </ul>
        </li>

        <li @click="showSubMenu('Logs')" :class="menu == 'Logs' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-journal-code></b-icon-journal-code>
              <span class="link_name">Logs</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Logs</a></li>
            <li><router-link :to="{ name: 'TicketsLog' }">Tickets Log</router-link></li>
            <li><router-link :to="{ name: 'UsersLog' }">Users Log</router-link></li>
          </ul>
        </li>

        <!-- Category -->
        <li @click="showSubMenu('Tickets')" :class="menu == 'Tickets' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-stickies></b-icon-stickies>
              <span class="link_name">Tickets</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Tickets</a></li>
            <li><router-link :to="{ name: 'TicketAdmin', params: { status: 'open' } }">Open</router-link></li>
            <li><router-link :to="{ name: 'TicketAdmin', params: { status: 'assigned' } }">Assigned</router-link></li>
            <li><router-link :to="{ name: 'TicketAdmin', params: { status: 'resolved' } }">Resolved</router-link></li>
            <li><router-link :to="{ name: 'TicketAdmin', params: { status: 'Reopened' } }">Reopened</router-link></li>
            <li><router-link :to="{ name: 'TicketAdmin', params: { status: 'breached' } }">Breached</router-link></li>
            <li><router-link :to="{ name: 'TicketAdmin', params: { status: 'escalated' } }">Escalated</router-link></li>
          </ul>
        </li>

        <!-- Knowledge Base -->
        <li>
          <router-link :to="{ name: 'KnowledgeBaseAdmin' }">
            <b-icon-book></b-icon-book>
            <span class="link_name">Knowledge Base</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Knowledge Base</a></li>
          </ul>
        </li>

        <li>
          <router-link :to="{ name: 'FAQAdmin' }">
            <b-icon-question-circle></b-icon-question-circle>
            <span class="link_name">FAQs</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">FAQs</a></li>
          </ul>
        </li>

        <!-- Categories -->
        <li @click="showSubMenu('Departments')" :class="menu == 'Departments' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-folder2></b-icon-folder2>
              <span class="link_name">Departments</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Departments</a></li>
            <li><router-link :to="{ name: 'CreateDepartmentAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'DepartmentsAdmin' }">View</router-link></li>
          </ul>
        </li>

        <!-- Categories -->
        <li @click="showSubMenu('Categories')" :class="menu == 'Categories' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-layers></b-icon-layers>
              <span class="link_name">Categories</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Categories</a></li>
            <li><router-link :to="{ name: 'CreateCategoryAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'CategoriesAdmin' }">View</router-link></li>
          </ul>
        </li>

        <!-- Subcategories -->
        <li @click="showSubMenu('Subcategories')" :class="menu == 'Subcategories' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-layers-half></b-icon-layers-half>
              <span class="link_name">Subcategories</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Subcategories</a></li>
            <li><router-link :to="{ name: 'CreateSubCategoryAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'SubCategoriesAdmin' }">View</router-link></li>
          </ul>
        </li>

        <!-- Custom Roles -->
        <!--  <li @click="showSubMenu('CustomRoles')" :class="menu == 'CustomRoles' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-folder></b-icon-folder>
              <span class="link_name">Custom Roles</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Custom Roles</a></li>
            <li><router-link :to="{ name: 'CreateCustomRoleAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'CustomRolesAdmin' }">View</router-link></li>
          </ul>
        </li> -->

        <!-- Custom Roles -->
        <li @click="showSubMenu('Users')" :class="menu == 'Users' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-people></b-icon-people>
              <span class="link_name">Users</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu" style="margin-bottom: 150px;">
            <li><a href="#" class="link_name">Users</a></li>
            <li><router-link to="/admin/user/create">Create new user</router-link></li>
            <!--  <li v-for="(r, i) in roles" :key="i">
              <router-link :to="{ name: 'UsersAdmin', params: { role: r.slug } }">{{ r.name }}</router-link>
            </li> -->
            <li><router-link :to="{ name: 'UsersAdmin', params: { role: 'first-level-support' } }">First Level</router-link></li>
            <li><router-link :to="{ name: 'UsersAdmin', params: { role: 'first-level-support-supervisor-1' } }">First Level Supv.1</router-link></li>
            <li><router-link :to="{ name: 'UsersAdmin', params: { role: 'first-level-support-supervisor-2' } }">First Level Supv.2</router-link></li>
            <li><router-link :to="{ name: 'UsersAdmin', params: { role: 'second-level-support' } }">Second Level</router-link></li>
            <li>
              <router-link :to="{ name: 'UsersAdmin', params: { role: 'second-level-support-supervisor-1' } }">Second Level Supv.1</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'UsersAdmin', params: { role: 'second-level-support-supervisor-2' } }">Second Level Supv.2</router-link>
            </li>
            <li><router-link :to="{ name: 'UsersAdmin', params: { role: 'admin' } }">Admins</router-link></li>
            <li><router-link :to="{ name: 'UsersAdmin', params: { role: 'customer' } }">Customers</router-link></li>
          </ul>
        </li>

        <!-- profile -->
        <!-- <li>
          <div class="profile-details">
            <router-link :to="{ name: 'ProfileAdmin' }">
              <div class="profile-content">
                <img src="../assets/default.png" alt="profile" width="52" height="52" />
              </div>

              <div class="name-job">
                <div class="profile_name">{{ authUser.Name }}</div>
                <div class="job">{{ authUser.Role }}</div>
              </div>
            </router-link>

            <Logout kind="iconic" />
          </div>
        </li> -->
      </ul>
    </div>

    <section class="home-section">
      <div class="home-content">
        <!-- <i class="bx bx-menu" @click="toggleMenu()"></i> -->
        <b-icon-list @click="toggleMenu()"></b-icon-list>
        <span class="text"></span>
        <div>
          <b-dropdown id="dropdown-1" :text="authUser.Name" class="m-md-2 " variant="secondary-outline">
            <b-dropdown-item href="https://support.up-ng.com/admin">UP Portal</b-dropdown-item>
            <b-dropdown-item href="https://support.payattitude.com/admin">Payattitude Portal</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :to="{ name: 'ProfileAdmin' }">Profile</b-dropdown-item>
            <Logout kind="dd-item" />
          </b-dropdown>
        </div>
      </div>
      <div class="cont">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Logout from '@/components/Logout.vue'

export default {
  components: { Logout },
  data() {
    return {
      compress: false,
      menu: '',

      roles: [],
      ticketStatuses: []
    }
  },

  mounted() {
    if (window.innerWidth < 768) {
      this.compress = true
    }
  },

  created() {
    window.addEventListener('resize', this.screenResize)
    axios.get('/admin/menu-items').then(res => {
      if (res.data.status == 'success') {
        this.roles = res.data.roles
        this.ticketStatuses = res.data.ticketStatuses
      }
    })
  },

  destroyed() {
    window.removeEventListener('resize', this.screenResize)
  },

  methods: {
    screenResize(e) {
      //console.log(e.srcElement.innerWidth);
      /* console.log(screen.width); */
      if (e.srcElement.innerWidth < 768) {
        this.compress = true
      } else {
        this.compress = false
      }
    },

    toggleMenu() {
      this.compress = !this.compress
    },

    showSubMenu(menu) {
      if (this.menu == menu) {
        this.menu = ''
      } else {
        this.menu = menu
      }
    }

    /*  */
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped></style>
